import * as React from "react";
import { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import { TextField } from "@mui/material";
import Axios from 'axios';
import Mailer from './components/Mailer';

import { useNavigate } from 'react-router-dom'; 

function Main({loginStatus, onLogoutClick, selectedTab}) {
  console.log("Entered main... selectedTab = "  + selectedTab);
  const navigate = useNavigate(); 

  console.log('in main, loginStatus = ' + loginStatus);

  useEffect(() => {
    if(!loginStatus) {
      console.log("Navigating back to / because of !loginStatus in []");
      navigate('/', { replace: true });
    }
  }, []);
  
  useEffect(() => {
    if(!loginStatus) {
      console.log("Navigating back to / because of !loginStatus in [loginStatus]");
      navigate('/', { replace: true });
    }
  }, [loginStatus]);

  console.log("reached past the two useEffects...");

  const getContents = () => {
      return <Mailer onLogoutClick={onLogoutClick} />
  }
  
  return <> { getContents() } </>
}

export default Main;
