import React, { useEffect, useState } from "react";
import Axios from 'axios';
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Main from './pages/main';
import Login from './pages/login';
import Register from './pages/register';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Verify from './pages/verify';

import Config from './config.json';

function App() {
  const [loginStatus, setLoginStatus] = useState(false);
  const [selectedTab, setSelectedTab] = useState("pointers");

  useEffect(() => {
console.log("Inside app.js/useEffect... loginstatus = " + loginStatus);
    
  }, [loginStatus]);


  const userAuthenticeted = () => {
    Axios.get(Config.URL + "/isUserAuth", {
      headers: {
        "x-access-token": localStorage.getItem("token"),
      },
    }).then((response) => {
      console.log(response);
    });
  };

  const onLogoutClick = () => {
    console.log("onLogoutClick invoked");
    localStorage.removeItem("token");
    localStorage.clear();
    setLoginStatus(false);
  }

  console.log("loginStatus = " + loginStatus);

  return (
    <>
<Router>
  <Navbar onLogoutClick={onLogoutClick} setSelectedTab={setSelectedTab} selectedTab={selectedTab} />
  <div className='App'>
    <Routes>
      <Route
        path='/'
        element={
          <Login
            loginStatus={loginStatus}
            setLoginStatus={setLoginStatus}
          />
        }
      />
      <Route
        path='/main'
        element={
        <Main 
          loginStatus={loginStatus}
          onLogoutClick={onLogoutClick}
          selectedTab={selectedTab}
        />}
      />
      <Route
        path='/register'
        element={
        <Register 
          loginStatus={loginStatus}
        />}
      />

      <Route
        path='/verify'
        element={
        <Verify />}
      />

    </Routes>
  </div>
  <Footer />  
</Router>
</>

  );
}

export default App;
