import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Add this
import { Grid, Typography } from '@mui/material';
import { TextField } from '@mui/material';
import { Button } from '@mui/material';
import Axios from 'axios';
import Paper from '@mui/material/Paper';

import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Input from '@mui/material/Input';
import FilledInput from '@mui/material/FilledInput';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';

import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Config from '../../config.json';


function Register ({setLoginStatus}) {
  const [email, setEmail] = useState ("");
  const [password, setPassword] = useState ("");
  const [showPassword, setShowPassword] = React.useState(false);

  const navigate = useNavigate(); 

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  useEffect(() => {

  }, []);

  const onEmailChange = (e) => {
    setEmail(e.target.value);
  }
  const onPasswordChange = (e) => {
    setPassword(e.target.value);
  }
  const register = () => {
    // Axios.post("http://localhost:3001/register", {
    Axios.post(Config.URL + "/register", {
      username: email,
      password: password,
    }).then((response) => {
      if(response.data.success === true) {
        alert("User has been added. We have sent an email with email-verifaction link. Please verify your email id.");
        navigate('/', { replace: true });
      } else {
        alert(response.data.message);
      }
      console.log(response);
    });
  }


  return (<>
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
      sx={{ minHeight: '100vh' }}
    >
      <Grid item xs={3}>
      <Paper variant="elevation" elevation={13} style={{padding:'4em'}} >
        <Typography variant="h4" gutterBottom style={{textAlign:'center'}}>Register</Typography>
        <div>
          <TextField label="Email" placeholder='Email id' value={email} onChange={onEmailChange} fullWidth={true} />
         
          <br /><br />

          <FormControl sx={{ width: '100%' }} variant="outlined">
          <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
          <OutlinedInput
            id="outlined-adornment-password"
            type={showPassword ? 'text' : 'password'}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
            label="Password"
            placeholder='Password' password value={password} onChange={onPasswordChange} fullwidth 
          />
        </FormControl>

          <br /><br />
          <Button variant="contained" onClick={register} >Register</Button>
        </div>
      </Paper>
      </Grid>
    </Grid>
  </>
  );
}

export default Register;
