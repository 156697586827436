import * as React from "react";
import { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import { TextField } from "@mui/material";
import Axios from 'axios';
import Typography from '@mui/material/Typography';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { Divider } from '@mui/material';
import { Grid } from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { ListItemText } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import Add from '@mui/icons-material/Add';
import Refresh from '@mui/icons-material/Refresh';
import Cancel from '@mui/icons-material/Cancel';
import CreateNewFolderOutlined from '@mui/icons-material/CreateNewFolderOutlined';
import Edit from '@mui/icons-material/Edit';
import Check from '@mui/icons-material/Check';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Config from '../../../config.json';


function Mailer({onLogoutClick}) {
  const [emailTargets, setEmailTargets] = useState ("");
  
  useEffect(() => {
  
  }, []);
  
   
  const onEmailTargetsChange = (e) => { setEmailTargets(e.target.value) }

  Axios.defaults.withCredentials = true;

  const sendEmails = () => {
    if(window.confirm("Are you sure you want to send the emails?")) {
 
      Axios.post(Config.URL + "/send_emails", {
        emailTargets: emailTargets,
        emailDestinationType: 'colleges'
      },
      {headers: {
        "x-access-token": localStorage.getItem("token"),
      }}).then((response) => {
        if(response.data.success === true) {
          alert("Emails have been sent successfully.");
          // setEmailTargets(''); Bhairav.. uncomment this later on...
        } else {
          alert(response.data.message);
        }
        console.log(response);
        console.log(response.data.message);
      });
    }
  }
 
  return <>
  <div>
    <br />
    <h3>Bulk E-Mailer</h3>
     <br />
    <Grid container spacing={3}>
      <Grid item xs={12} md={4}>
      </Grid>
      <Grid item xs="12" md={8}>
        <div>
          <div>
            <TextField label="EmailTargets" value={emailTargets} onChange={onEmailTargetsChange} multiline fullWidth={true} maxRows={12} /><br />
          </div>
          <br />
          <Button variant="contained" onClick={sendEmails} >Send Emails</Button>
        </div>
      </Grid>
    </Grid>
    <br />
  </div>
  </>
}

export default Mailer;
