import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Add this
import { Grid, Typography } from '@mui/material';
import { TextField } from '@mui/material';
import { Button } from '@mui/material';
import Axios from 'axios';
import Paper from '@mui/material/Paper';
import Config from '../../config.json';

function Verify () {
  const [email, setEmail] = useState ("");
  const [secretCode, setSecretCode] = React.useState("");
  const [message, setMessage] = React.useState("");

  const navigate = useNavigate(); 
  const queryParameters = new URLSearchParams(window.location.search)

  // useEffect(() => {
  //   var email = queryParameters.get("email");
  //   setEmail(email);
  //   var code = queryParameters.get("code");
  //   setSecretCode(code);
  // }, []);

  const onEmailChange = (e) => {
    setEmail(e.target.value);
  }
  const onSecretCodeChange = (e) => {
    setSecretCode(e.target.value);
  }

  const onVerifyClick = () => {
    Axios.post(Config.URL + "/verifyEmail", {
      email: email,
      code: secretCode,
    }).then((response) => {
      alert(JSON.stringify(response));
      alert(response.data.message);
      if(response.data.success === true) {
        
      }
      // setMessage(response.data.message);
      console.log(response);
    });
  }

  return (<>
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
      sx={{ minHeight: '100vh' }}
    >
      <Grid item xs={3}>
      <Paper variant="elevation" elevation={13} style={{padding:'4em'}} >
        <Typography variant="h4" gutterBottom style={{textAlign:'center'}}>Verify</Typography>
        <div>
          <TextField label="Email" placeholder='Email id' value={email} onChange={onEmailChange} fullWidth={true} />
          <br /><br />
          <TextField label="Code" placeholder='Code' value={secretCode} onChange={onSecretCodeChange} fullWidth={true} />
          <br /><br />
          <Button variant="contained" onClick={onVerifyClick} >Verify</Button>
        </div>
        <br /><br />
        <Typography variant="h4" gutterBottom style={{textAlign:'center'}}>{message}</Typography>
      </Paper>
      </Grid>
    </Grid>
  </>
  );
}

export default Verify;
