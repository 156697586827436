import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom'; // Add this
import { Grid, Typography } from '@mui/material';
import { TextField } from '@mui/material';
import { Button } from '@mui/material';
import Axios from 'axios';

import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Input from '@mui/material/Input';
import FilledInput from '@mui/material/FilledInput';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Paper from '@mui/material/Paper';

import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Config from '../../config.json';


function Login ({loginStatus, setLoginStatus}) {
  const [email, setEmail] = useState ("");
  const [password, setPassword] = useState ("");
  const [showPassword, setShowPassword] = React.useState(false);
  // const oldLoginStatus = useRef(false);


  const navigate = useNavigate(); 

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  // useEffect(() => {
  //   if(loginStatus === true) {
  //     if(oldLoginStatus.current === false) {
  //       oldLoginStatus.current = true;
  //       alert("about to redirect to main");
  //       navigate('/main', { loginStatus: true });
  //     } else {
  //       oldLoginStatus.current = false;
  //     }
  //   }
    
  // }, [loginStatus]);

  const onEmailChange = (e) => {
    setEmail(e.target.value);
  }
  const onPasswordChange = (e) => {
    setPassword(e.target.value);
  }

  const login = () => {

    Axios.post(Config.URL + "/login", {
      username: email,
      password: password,
    }).then((response) => {
      if (!response.data.auth) {
        console.log("encountered !response.data.auth. logging out")
        setLoginStatus(false);
        alert(response.data.message);
      } else {
        console.log(JSON.stringify(response.data));
        localStorage.setItem("token", response.data.token)
        localStorage.setItem("username", email);
        setLoginStatus(true);

        navigate('/main', { loginStatus: true });

        // if(loginStatus === true) {
        //   navigate('/main', { loginStatus: true });
        // } else {
        //   setLoginStatus(true);
        // }
      }
    });
  };

  return (<>
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
      sx={{ minHeight: '100vh' }}
    >
      <Grid item xs={3}>
      <Paper variant="elevation" elevation={13} style={{padding:'4em'}} >
        <Typography variant="h4" gutterBottom style={{textAlign:'center'}}>Login</Typography>
        <div>
          <TextField label="Email" placeholder='Email id' value={email} onChange={onEmailChange} fullWidth={true} />
         
          <br /><br />

          <FormControl sx={{ width: '100%' }} variant="outlined">
          <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
          <OutlinedInput
            id="outlined-adornment-password"
            type={showPassword ? 'text' : 'password'}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
            label="Password"
            placeholder='Password' password value={password} onChange={onPasswordChange} fullwidth 
          />
        </FormControl>

          <br /><br />
          <Button variant="contained" onClick={login} >Login</Button>
        </div>
      </Paper>
      </Grid>
    </Grid>
  </>
  );
}

export default Login;
